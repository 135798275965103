import { App, defineAsyncComponent } from 'vue'

import ApplyingForFinancing from '@/components/forms/applyingForFinancing.vue'

const ContactForm = defineAsyncComponent(
  () => import('@/components/forms/contact.vue')
)
const ContactPartnerForm = defineAsyncComponent(
  () => import('@/components/forms/contactPartner.vue')
)
const ContactSimpleForm = defineAsyncComponent(
  () => import('@/components/forms/contactSimple.vue')
)
const ContactModal = defineAsyncComponent(
  () => import('@/components/forms/contactModal.vue')
)
const SubscribeNews = defineAsyncComponent(
  () => import('@/components/forms/subscribeNews.vue')
)

const UnsubscribeNews = defineAsyncComponent(
  () => import('@/components/forms/unsubscribeNews.vue')
)
const EquipmentCheck = defineAsyncComponent(
  () => import('@/components/forms/equipmentCheck.vue')
)
const EquipmentDelivery = defineAsyncComponent(
  () => import('@/components/forms/equipmentDelivery.vue')
)
const EquipmentMonthlyPrice = defineAsyncComponent(
  () => import('@/components/forms/equipmentMonthlyPrice.vue')
)
const EquipmentOffer = defineAsyncComponent(
  () => import('@/components/forms/equipmentOffer.vue')
)
const EquipmentBuyNowWithCommercial = defineAsyncComponent(
  () => import('@/components/forms/equipmentBuyNowWithCommercial.vue')
)
const OfferContact = defineAsyncComponent(
  () => import('@/components/forms/offerContact.vue')
)
const CollectionForm = defineAsyncComponent(
  () => import('@/components/forms/collection.vue')
)
const EquipmentGetAnOfferCta = defineAsyncComponent(
  () => import('@/components/forms/equipmentGetAnOfferCta')
)

const FinancingApplicationCreate = defineAsyncComponent(
  () =>
    import(
      '@/modules/cabinet/modules/FinancingApplication/components/Create.vue'
    )
)

const BrandModelRequest = defineAsyncComponent(
  () => import('@/components/forms/brandModelRequest.vue')
)

const OfferModeration = defineAsyncComponent(
  () => import('@/components/forms/offerModeration.vue')
)

const DownloadBondsGuide = defineAsyncComponent(
  () => import('@/components/forms/downloadBondsGuide.vue')
)

const BondInvest = defineAsyncComponent(
  () => import('@/components/forms/bondInvest.vue')
)

const ModeratorOfferEdit = defineAsyncComponent(
  () => import('@/components/forms/moderatorOfferEdit.vue')
)

const install = (app: App<Element>): void => {
  const prefix = 'Form'
  app
    .component(`Contact${prefix}`, ContactForm)
    .component(`ContactPartner${prefix}`, ContactPartnerForm)
    .component(`ContactSimple${prefix}`, ContactSimpleForm)
    .component(`ContactModal${prefix}`, ContactModal)
    .component(`SubscribeNews${prefix}`, SubscribeNews)
    .component(`Unsubscribe${prefix}`, UnsubscribeNews)
    .component(`EquipmentCheck${prefix}`, EquipmentCheck)
    .component(`EquipmentDelivery${prefix}`, EquipmentDelivery)
    .component(`EquipmentMonthlyPrice${prefix}`, EquipmentMonthlyPrice)
    .component(
      `EquipmentBuyNowWithCommercial${prefix}`,
      EquipmentBuyNowWithCommercial
    )
    .component(
      `FinancingApplicationCreate${prefix}`,
      FinancingApplicationCreate
    )
    .component(`EquipmentOffer${prefix}`, EquipmentOffer)
    .component(`Collection${prefix}`, CollectionForm)
    .component(`OfferContact${prefix}`, OfferContact)
    .component(`ApplyingForFinancing${prefix}`, ApplyingForFinancing)
    .component(`OfferModeration${prefix}`, OfferModeration)
    .component(`DownloadBondsGuide${prefix}`, DownloadBondsGuide)

    .component('EquipmentGetAnOfferCta', EquipmentGetAnOfferCta)
    .component('BrandModelRequest', BrandModelRequest)
    .component(`BondInvest${prefix}`, BondInvest)
    .component(`ModeratorOfferEdit${prefix}`, ModeratorOfferEdit)
}

export default {
  install,
}
