import { App, defineAsyncComponent } from 'vue'

import LoadingWrapper from './LoadingWrapper.vue'
import ModalTrigger from './modals/ModalTrigger.vue'
import NestedMenuWrap from './NestedMenuWrap.vue'
import GalleryView from './GalleryView'
import UiSnapCarousel from './UiSnapCarousel'
import AppIcon from './AppIcon.vue'
import UiLightbox from './UiLightbox.vue'
import StepsController from './StepsController.vue'

const Sidebar = defineAsyncComponent(() => import('./Sidebar.vue'))
const UiToggler = defineAsyncComponent(() => import('./UiToggler'))
const UiAccordion = defineAsyncComponent(() => import('./UiAccordion.vue'))
const UiVideoView = defineAsyncComponent(() => import('./UiVideoView'))
const SocialShare = defineAsyncComponent(() => import('./SocialShare.vue'))
const Timer = defineAsyncComponent(() => import('./Timer.vue'))
const UiPagination = defineAsyncComponent(() => import('./UiPagination.vue'))
const UiEllipsisContent = defineAsyncComponent(() => import('./UiEllipsisContent.vue'))
const UiSocialShareMin = defineAsyncComponent(() => import('./UiSocialShareMin.vue'))
const SelectionController = defineAsyncComponent(() => import('./SelectionController'))
const AllSelectionController = defineAsyncComponent(() => import('./AllSelectionController.vue'))
const CategoriesMenuMobileWrap = defineAsyncComponent(() => import('./CategoriesMenuMobileWrap.vue'))
const Switch = defineAsyncComponent(() => import('./UiSwitch.vue'))
const TableScroller = defineAsyncComponent(() => import('./TableScroller.vue'))
const CategoriesHeaderMenu = defineAsyncComponent(() => import('./CategoriesHeaderMenu.vue')) 
const MapView = defineAsyncComponent(() => import('./MapView.vue'))
const AccordionListController = defineAsyncComponent(() => import('./AccordionListController'))
const VueOrderingController = defineAsyncComponent(() => import('./VueOrderingController'))

const install = (app: App<Element>, prefix = 'Ui'): void => {
  app
    .component(`${prefix}Sidebar`, Sidebar)
    .component(`${prefix}ModalTrigger`, ModalTrigger)
    .component(`${prefix}Timer`, Timer)
    .component(`${prefix}Switch`, Switch)
    .component(`${prefix}TableScroller`, TableScroller)
    .component('StepsController', StepsController)
    .component('UiToggler', UiToggler)
    .component('UiVideoView', UiVideoView)
    .component('UiAccordion', UiAccordion)
    .component('AppIcon', AppIcon)
    .component('SocialShare', SocialShare)
    .component('LoadingWrapper', LoadingWrapper)
    .component('UiPagination', UiPagination)
    .component('UiSnapCarousel', UiSnapCarousel)
    .component('UiEllipsisContent', UiEllipsisContent)
    .component('UiSocialShareMin', UiSocialShareMin)
    .component('UiLightbox', UiLightbox)
    .component('SelectionController', SelectionController)
    .component('AllSelectionController', AllSelectionController)
    .component('NestedMenuWrap', NestedMenuWrap)
    .component('CategoriesMenuMobileWrap', CategoriesMenuMobileWrap)
    .component('UiGalleryView', GalleryView)
    .component('CategoriesHeaderMenu', CategoriesHeaderMenu)
    .component('MapView', MapView)
    .component('AccordionListController', AccordionListController)
    .component('VueOrderingController', VueOrderingController)
  }

export default {
  install,
}
