import { markRaw, defineAsyncComponent, Component, DefineComponent } from 'vue'
import {
  useModal,
  useModalSlot,
  ComponentProps,
  vueFinalModalProps,
  UseModalOptions,
  UseModalReturnType,
  VueFinalModal,
} from 'vue-final-modal'

const ModalWrapper = defineAsyncComponent(
  () => import('@/components/ui/modals/ModalWrapper.vue')
)

type MayBeAny = any

type FinishFn = (arg: MayBeAny) => void
type FinishPromise = (arg: MayBeAny) => Promise<MayBeAny> | Promise<void>
export type Finish = FinishFn | FinishPromise

interface ModalControls {
  open: () => Promise<string>
  close: () => Promise<string>
  patchOptions: (options: MayBeAny) => void
  options: Record<string, any>
  destroy: () => void
}

export function useModalOpener(
  componentProps: {
    component: DefineComponent | Component | string
    [key: string]: MayBeAny
  },
  modalProps: Record<string, any> = {},
  finish: Finish = (arg: unknown) => undefined
): ModalControls {
  const onFinish = (arg: unknown) =>
    new Promise((resolve, reject) => {
      if (arg !== undefined) {
        resolve(arg)
        return
      }
      reject()
    })
      .then((result) => {
        finish(result)
        close()
      })
      .catch((err) => {
        console.warn('use-modal-on-finish', err)
      })

  const {
    options,
    open,
    close,
    patchOptions,
    destroy,
  }: UseModalReturnType<Component> = useModal<Component>({
    component: VueFinalModal,
    attrs: {
      onClose() {
        close()
      },
      onFinish,
      clickToClose: true,
      ...modalProps,
      // ...componentProps,
    },
    slots: {
      default: useModalSlot({
        component: markRaw(ModalWrapper),
        attrs: {
          onClose() {
            close()
          },
          onFinish,
          clickToClose: true,
          ...componentProps,
        },
      }),
    },
  } as UseModalOptions<Component>)

  return { options, open, close, patchOptions, destroy }
}
