/**
 * Disables dates before the specified date.
 * @param {Object} options - Options for disabling dates.
 * @param {Date} [options.date=new Date()] - The date to disable dates before.
 * @param {boolean} [options.include=true] - Whether to include the specified date or not.
 * @returns {function(number): boolean} - A function that returns true if the given date is before the specified date, and false otherwise.
 */
export function disableBeforeDate({ date = new Date(), include = true }) {
  return (d: number) => d < new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}

/**
 * Checks if a given date is within the specified date range.
 * @param {Object} options - Options for the date range.
 * @param {Date} [options.min=new Date()] - The minimum date in the range.
 * @param {Date} [options.max=new Date()] - The maximum date in the range.
 * @param {boolean} [options.include=true] - Whether to include the minimum and maximum dates in the range.
 * @returns {function(number): boolean} - A function that returns true if the given date is within the specified range, and false otherwise.
 */
export function allowDateInRange({
  min = new Date(),
  max = new Date(),
  include = true,
}) {
  return (d: number) =>
    d < new Date(min).setHours(include ? 0 : 24, 0, 0, 0) ||
    d > new Date(max).setHours(include ? 0 : 24, 0, 0, 0)
}

/**
 * Disables dates after the specified date.
 * @param {Object} options - Options for disabling dates.
 * @param {Date} [options.date=new Date()] - The date to disable dates after.
 * @param {boolean} [options.include=true] - Whether to include the specified date or not.
 * @returns {function(number): boolean} - A function that returns true if the given date is after the specified date, and false otherwise.
 */
export function disableAfterDate({ date = new Date(), include = true }) {
  return (d: number) => d > new Date(date).setHours(include ? 0 : 24, 0, 0, 0)
}

/**
 * Extracts the video ID and generates the embed and preview URLs for a given video URL.
 * @param {string} url - The URL of the video.
 * @returns {{ embedUrl: string, previewUrl: string }} - An object containing the embed URL and preview URL for the video.
 * @throws {Error} - Throws an error if the video URL cannot be parsed.
 */
export function getVideoId(url: string): {
  embedUrl: string
  previewUrl: string
} {
  // eslint-disable-next-line no-useless-escape
  const regExpYoutube = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
  // eslint-disable-next-line no-useless-escape
  const regExpVimeo = /^.*(?:vimeo.com)\/(?:channels\/|channels\/\w+\/|groups\/[^\/]*\/videos\/|album\/\d+\/video\/|video\/|)(\d+)(?:$|\/|\?)/;
  const matchY = url.match(regExpYoutube);
  const matchV = url.match(regExpVimeo);
  const youtubeIdLength = 11;
  let embedUrl;
  let previewUrl;
  if (matchY && matchY[2].length === youtubeIdLength) {
    const id = matchY[2]
    embedUrl = `https://www.youtube.com/embed/${id}?enablejsapi=1&modestbranding=1&rel=0&autoplay=1&mute=0&iv_load_policy=3`
    // this.embedUrl = `https://www.youtube.com/embed/tgbNymZ7vqY?autoplay=1&mute=1"`;
    previewUrl = `https://img.youtube.com/vi/${id}/hq720.jpg`
    return { embedUrl, previewUrl }
  }
  if (matchV && matchV[1]) {
    const id = matchV[1]
    embedUrl = `https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&autoplay=1`
    previewUrl = `https://vumbnail.com/${id}.jpg`
    return { embedUrl, previewUrl }
  }
  throw new Error(`error parsed link: ${url}`)
}


/**
 * Handles the sticky header behavior based on the scroll direction.
 * Adds or removes CSS classes to the document body to indicate the scroll direction.
 */
export function headerSticky() {
  const { body } = document;
  const scrollUpClass = 'scroll-up';
  const scrollDownClass = 'scroll-down';
  let lastScroll = 0;

  const handleScroll = () => {
    const currentScroll = window.scrollY;

    // Early exit if scroll is at the top
    if (currentScroll <= 0) {
      body.classList.remove(scrollUpClass, scrollDownClass);
      return;
    }

    const isScrollingDown = currentScroll > lastScroll;
    
    // Add or remove classes based on scroll direction
    body.classList.toggle(scrollDownClass, isScrollingDown);
    body.classList.toggle(scrollUpClass, !isScrollingDown);

    lastScroll = currentScroll;
  };

  // Optional: Debouncing to avoid excessive execution
  const debounce = (fn: () => void, delay: number) => {
    let timeoutId: number;
    return () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(fn, delay);
    };
  };

  const debouncedHandleScroll = debounce(handleScroll, 10);

  window.addEventListener('scroll', debouncedHandleScroll, { passive: true });

  // Cleanup function to remove event listener
  return () => {
    window.removeEventListener('scroll', debouncedHandleScroll);
  };
}

export function scrollIntoView(id: string) {
  const element = document.getElementById(id)
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
}