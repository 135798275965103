import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Fragment as _Fragment, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers } from "vue"

const _hoisted_1 = { ref: "stepperContent" }
const _hoisted_2 = { class: "tip-wrapper sk-space_mt sk-space_mt--4-md sk-space_mt--6-mac" }
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "sk-space_mt sk-space_mt--5 sk-space_mt--8-md sk-space_mt--10-mac" }
const _hoisted_5 = {
  key: 1,
  class: "g-row g-row--appearance_spaced g-row--space_lg g-row--space_2xl-mac g-row--justify_end sk-space_mt sk-space_mt--5 sk-space_mt--8-md sk-space_mt--10-mac"
}
const _hoisted_6 = {
  key: 0,
  class: "g-cell g-cols g-cols--12 g-cols--6-lg g-cols--5-xl"
}
const _hoisted_7 = { class: "applying-for-financing-video" }
const _hoisted_8 = {
  key: 0,
  class: "applying-for-financing-video__image-wrap"
}
const _hoisted_9 = { class: "ds-aspect-ratio ds-aspect-ratio--ratio_16x9 ds-aspect-ratio--appearance_cuted ds-aspect-ratio--appearance_covered" }
const _hoisted_10 = ["srcset"]
const _hoisted_11 = ["onClick", "src", "alt"]
const _hoisted_12 = ["onClick"]
const _hoisted_13 = {
  class: "ds-aspect-ratio ds-aspect-ratio--appearance_cuted ds-aspect-ratio--appearance_filled ds-aspect-ratio--appearance_fit ds-aspect-ratio--ratio_16x9",
  key: "video"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "tip-wrapper tip-wrapper--variant_full" }
const _hoisted_16 = ["innerHTML"]
const _hoisted_17 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_18 = { class: "g-row g-row--appearance_spaced" }
const _hoisted_19 = { class: "g-cell g-cols g-cols--12 g-cols--8-lg g-cols--4-xl" }
const _hoisted_20 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_21 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_22 = { class: "ds-inliner ds-inliner--size_lg" }
const _hoisted_23 = { class: "ds-inliner__body" }
const _hoisted_24 = ["src"]
const _hoisted_25 = { class: "g-cell g-cols g-cols--10" }
const _hoisted_26 = { class: "g-row g-row--appearance_nowrap g-row--align_center sk-space_pl sk-space_pl--0" }
const _hoisted_27 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}
const _hoisted_28 = ["src"]
const _hoisted_29 = { class: "g-cell g-cols g-cols--10 ellipsis-content ellipsis-content--1" }
const _hoisted_30 = { class: "g-cell g-cols g-cols--12" }
const _hoisted_31 = { class: "ds-caption ds-caption--size_sm ds-caption--appearance_start" }
const _hoisted_32 = { class: "global-error" }
const _hoisted_33 = { class: "global-error" }
const _hoisted_34 = { class: "global-error" }
const _hoisted_35 = {
  key: 0,
  class: "g-cell g-cols g-cols--narrow"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_i_play_circle = _resolveComponent("i-play-circle")!
  const _component_UiVideoView = _resolveComponent("UiVideoView")!
  const _component_d_control_recaptcha = _resolveComponent("d-control-recaptcha")!
  const _component_DSelect = _resolveComponent("DSelect")!
  const _component_vee_error = _resolveComponent("vee-error")!
  const _component_ControlButton = _resolveComponent("ControlButton")!
  const _component_VeeForm = _resolveComponent("VeeForm")!
  const _component_StepsController = _resolveComponent("StepsController")!

  return (_openBlock(), _createBlock($setup["LoadingWrapper"], { loading: $setup.isLoad }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_StepsController, {
          config: $setup.stepsConfig,
          step: $setup.currentStep,
          ref: "stepper",
          "onUpdate:activeStep": $setup.setFlags
        }, {
          default: _withCtx(({ currentStep, nextStep }) => [
            ($setup.stepsConfig[currentStep].component)
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createElementVNode("div", _hoisted_2, [
                    _createElementVNode("div", {
                      class: "tip-wrapper__content",
                      innerHTML: $setup.stepDescription[currentStep]
                    }, null, 8, _hoisted_3)
                  ]),
                  _createElementVNode("div", _hoisted_4, [
                    (_openBlock(), _createBlock(_resolveDynamicComponent($setup.stepsConfig[currentStep].component), {
                      initial: $setup.initial,
                      "default-country": $setup.props.defaultCountry,
                      "onUpdate:step": e => $setup.saveAndGoNext(e, currentStep + 1)
                    }, null, 40, ["initial", "default-country", "onUpdate:step"]))
                  ])
                ], 64))
              : (_openBlock(), _createElementBlock("div", _hoisted_5, [
                  ($props.videoUrl)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                        _createVNode(_component_UiVideoView, { url: $props.videoUrl }, {
                          default: _withCtx(({ embedUrl, isShowVideo, showMeVideo, preview }) => [
                            _createElementVNode("div", _hoisted_7, [
                              (!isShowVideo)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createElementVNode("picture", _hoisted_9, [
                                      _createElementVNode("source", {
                                        type: "image/webp",
                                        srcset: $props.videoPreview.webp
                                      }, null, 8, _hoisted_10),
                                      _createElementVNode("img", {
                                        class: "ds-aspect-ratio__body image-cover",
                                        onClick: ($event: any) => (showMeVideo()),
                                        src: $props.videoPreview.origin,
                                        alt: $props.videoPreviewAlt,
                                        width: "1005",
                                        height: "565"
                                      }, null, 8, _hoisted_11)
                                    ])
                                  ]))
                                : _createCommentVNode("", true),
                              (!isShowVideo)
                                ? (_openBlock(), _createElementBlock("div", {
                                    key: 1,
                                    class: "applying-for-financing-video__play-btn",
                                    onClick: ($event: any) => (showMeVideo())
                                  }, [
                                    _createVNode(_component_i_play_circle)
                                  ], 8, _hoisted_12))
                                : _createCommentVNode("", true),
                              isShowVideo
                                ? (_openBlock(), _createElementBlock("figure", _hoisted_13, [
                                    _createElementVNode("iframe", {
                                      class: "ds-aspect-ratio__body",
                                      src: embedUrl,
                                      frameborder: "0",
                                      allow: "autoplay; encrypted-media",
                                      allowfullscreen: ""
                                    }, null, 8, _hoisted_14)
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 1
                        }, 8, ["url"])
                      ]))
                    : _createCommentVNode("", true),
                  _createElementVNode("div", {
                    class: _normalizeClass(["g-cell g-cols g-cols--12", [$props.videoUrl ? 'g-cols--6-lg g-cols--7-xl' : '']])
                  }, [
                    _createElementVNode("div", _hoisted_15, [
                      _createElementVNode("div", {
                        class: "tip-wrapper__content",
                        innerHTML: $setup.stepDescription[currentStep]
                      }, null, 8, _hoisted_16)
                    ])
                  ], 2),
                  _createElementVNode("div", _hoisted_17, [
                    _createVNode(_component_VeeForm, {
                      ref: "banksFormRef",
                      onSubmit: $setup.submit
                    }, {
                      default: _withCtx(({ errors, isSubmitting, meta }) => [
                        _createVNode($setup["LoadingWrapper"], {
                          loading: $setup.banksFormLoading || isSubmitting
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_18, [
                              _createElementVNode("div", _hoisted_19, [
                                _createVNode(_component_d_control_recaptcha, {
                                  class: "control-descriptor--clear",
                                  modelValue: $setup.getBankLinkForm.recaptcha,
                                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.getBankLinkForm.recaptcha) = $event)),
                                  ref: "captchaRef",
                                  onVerify: _cache[1] || (_cache[1] = ($event: any) => ($setup.saveCaptcha($event, $setup.banksFormRef)))
                                }, null, 8, ["modelValue"]),
                                _createVNode(_component_DSelect, {
                                  rules: 'required',
                                  name: "bank",
                                  id: "bank",
                                  "label-by": 'name',
                                  "track-by": 'id',
                                  "allow-empty": false,
                                  "input-label": _ctx.$t('Bank'),
                                  modelValue: $setup.getBankLinkForm.bank,
                                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (($setup.getBankLinkForm.bank) = $event)),
                                  "internal-search": true,
                                  onSelect: _cache[3] || (_cache[3] = ($event: any) => ($setup.getCaptcha())),
                                  searchable: false,
                                  loading: $setup.banksLoading,
                                  options: $setup.banksList
                                }, {
                                  option: _withCtx(({ option }) => [
                                    _createElementVNode("div", _hoisted_20, [
                                      (option.logo)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_21, [
                                            _createElementVNode("div", _hoisted_22, [
                                              _createElementVNode("div", _hoisted_23, [
                                                _createElementVNode("img", {
                                                  class: "img-block",
                                                  src: option.logo
                                                }, null, 8, _hoisted_24)
                                              ])
                                            ])
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_25, [
                                        _createElementVNode("span", null, _toDisplayString(" " + option.name), 1)
                                      ])
                                    ])
                                  ]),
                                  singleLabel: _withCtx(({ option }) => [
                                    _createElementVNode("div", _hoisted_26, [
                                      (option.logo)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
                                            _createElementVNode("img", {
                                              class: "img-block bank-logo-image",
                                              src: option.logo,
                                              width: "24",
                                              height: "24"
                                            }, null, 8, _hoisted_28)
                                          ]))
                                        : _createCommentVNode("", true),
                                      _createElementVNode("div", _hoisted_29, [
                                        _createElementVNode("span", null, _toDisplayString(" " + option.name), 1)
                                      ])
                                    ])
                                  ]),
                                  _: 1
                                }, 8, ["input-label", "modelValue", "loading", "options"])
                              ]),
                              _createElementVNode("div", _hoisted_30, [
                                _createElementVNode("p", _hoisted_31, _toDisplayString(_ctx.$t('Provide a bank statement for the company\'s account.')), 1)
                              ]),
                              _createVNode(_component_vee_error, {
                                class: "g-cell g-cols g-cols--12",
                                name: "recaptcha",
                                as: "div"
                              }, {
                                default: _withCtx(({ message }) => [
                                  _createElementVNode("div", _hoisted_32, _toDisplayString(message), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_vee_error, {
                                class: "g-cell g-cols g-cols--12",
                                name: "financingApplication",
                                as: "div"
                              }, {
                                default: _withCtx(({ message }) => [
                                  _createElementVNode("div", _hoisted_33, _toDisplayString(message), 1)
                                ]),
                                _: 1
                              }),
                              _createVNode(_component_vee_error, {
                                class: "g-cell g-cols g-cols--12",
                                name: "nonFieldErrors",
                                as: "div"
                              }, {
                                default: _withCtx(({ message }) => [
                                  _createElementVNode("div", _hoisted_34, _toDisplayString(message), 1)
                                ]),
                                _: 1
                              }),
                              ($setup.bankLink)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                                    _createVNode(_component_ControlButton, {
                                      type: "submit",
                                      view: ['full'],
                                      styling: "primary",
                                      onClick: _withModifiers($setup.redirectToBankLink, ["prevent"]),
                                      disabled: isSubmitting
                                    }, {
                                      default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('Open banking button')), 1)
                                      ]),
                                      _: 2
                                    }, 1032, ["disabled"])
                                  ]))
                                : _createCommentVNode("", true)
                            ])
                          ]),
                          _: 2
                        }, 1032, ["loading"])
                      ]),
                      _: 1
                    }, 512)
                  ])
                ]))
          ]),
          _: 1
        }, 8, ["config", "step"])
      ], 512)
    ]),
    _: 1
  }, 8, ["loading"]))
}