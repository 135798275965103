import { r, createSender, createFileSender } from '@/plugins/resource/resource'
import { queryPostfix as q } from '@/plugins/resource/api'

import { baseResource } from '@/plugins/resource'
import { prefixLanguage } from '@/utils/urls'

import { friendlyUrlGenerator } from '@/components/offersCatalog/utils'
const MODEL = 'catalog'

const BRAND_MODEL = `${MODEL}/brands`
const CATEGORIES_MODEL = `${MODEL}/categories`
const OFFERS_PHOTOS_OWNED_MODEL = `${MODEL}/offer-photos/owned`
const OFFERS_MODEL = `${MODEL}/offers`
const OFFERS_OWNED_MODEL = `${MODEL}/offers/owned`

export const catalogApi = {
  attributes: {
    config: r(q('/attributes/config/', ['category_ids']), MODEL),
    values: r(q('/attributes/values/', ['search', 'attribute_ids']), MODEL),
  },
  brands: {
    list: r(q('/list/', ['search', 'ids']), BRAND_MODEL),
    modelList: r(
      q('/models/list/', ['search', 'ids', 'brand_ids']),
      BRAND_MODEL
    ),
  },
  categories: {
    list: r(
      q('/list/', ['search', 'ids', 'child_of', 'is_root']),
      CATEGORIES_MODEL
    ),
    hierarchy: r('/hierarchy/', CATEGORIES_MODEL),
    menu: r('/menu/', CATEGORIES_MODEL),
  },

  offerLabels: {
    list: r(q('/offer-labels/list/', ['search']), MODEL),
  },

  offerPhotosOwned: {
    create: r('/create/', OFFERS_PHOTOS_OWNED_MODEL, createFileSender),
    list: r(q('/list/'), OFFERS_PHOTOS_OWNED_MODEL),
    update: r('/update{/id}/', OFFERS_PHOTOS_OWNED_MODEL, createSender).config(
      'method',
      'PATCH'
    ),
  },

  offers: {
    callUsClick: r('/call-us-click/',OFFERS_MODEL, createFileSender),
    favoriteList: r(q('/favorite/list/', ['sort']), OFFERS_MODEL),
    detail: r('/detail{/id}/', OFFERS_MODEL),
    filtersView: r(
      '/filters/view/',
      OFFERS_MODEL,
      createSender,
    ),
    filtersAvailable: r(
      q('/filters/available/', [
        'ids',
        'token_string',
        'search_string',
      ]),
      OFFERS_MODEL
    ),
    catalogFiltersAvailable: r(
      q('/filters/catalog-available/', [
        'ids',
        'token_string',
        'search_string',
      ]),
      OFFERS_MODEL
    ),
    list: r(
      q('/list/', [
        'ids',
        'token_string',
        'search_string',
        'sort',
      ]),
      OFFERS_MODEL
    ),
    update: r('/update{/id}/', OFFERS_MODEL, createSender).config(
      'method',
      'PATCH'
    ),
    owned: {
      counters: r('/counters/', OFFERS_OWNED_MODEL),
      archive: r('/archive/', OFFERS_OWNED_MODEL, createSender),
      publish: r('/publish/', OFFERS_OWNED_MODEL, createSender),
      sold: r('/sold/', OFFERS_OWNED_MODEL, createSender),
      create: r('/create/', OFFERS_OWNED_MODEL, createSender),
      clone: r('/clone/', OFFERS_OWNED_MODEL, createSender),
      detail: r('/detail{/id}/', OFFERS_OWNED_MODEL),
      filters: r(
        q('/filters/available', [
          'filters',
          'ids',
          'search',
          'owner',
          'brands_ids',
          'category_ids',
          'label_ids',
          'status_moderation',
          'status_activity',
          'token_string',
        ]),
        OFFERS_OWNED_MODEL
      ),
      list: r(
        q('/list/', [
          'filters',
          'ids',
          'search',
          'brands_ids',
          'category_ids',
          'label_ids',
          'status_moderation',
          'status_activity',
          'token_string',
        ]),
        OFFERS_OWNED_MODEL
      ),
      update: r('/update{/id}/', OFFERS_OWNED_MODEL, createSender).config(
        'method',
        'PATCH'
      ),
    },
    setFailedStatus: r('/set-failed-status/', OFFERS_MODEL, createSender),
  },
} as const

const CATALOG_MODEL = '/categories/'
const CATALOG_OWNER_MODEL = '/catalog/'

const FILTERS_LIST_URL_POSTFIX = 'ajax/'

const order = {
  path: [
    'prefix',
    'owner',
    'label',
    'category',
    'postfix',
    'locationCountries',
    'categories',
    'brands',
    'price_min',
    'price_max',
    'first_registration_min',
    'first_registration_max',
    'brand_models',
    'brands_models',
    'filters',
    'token_string',
  ],
  query: ['pagination_offset'],
}

export const ajaxCatalogList = friendlyUrlGenerator(
  [prefixLanguage(CATALOG_MODEL), FILTERS_LIST_URL_POSTFIX],
  baseResource,
  order
)
export const ajaxOwnerCatalogList = friendlyUrlGenerator(
  [prefixLanguage(CATALOG_OWNER_MODEL), FILTERS_LIST_URL_POSTFIX],
  baseResource,
  order
)
