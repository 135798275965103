/**
 * Parses the URL and extracts the query parameters and page information.
 *
 * @param {string} splittedPart - The part of the URL to split on.
 * @returns {object} - An object containing the parsed URL parameters and the page number.
 */
export function friendlyUrlParser(splittedPart: string) {
  let path = decodeURIComponent(new URL(window.location.href).toString());
  const zero = 0;
  let page = 1
  // let p = ''
  const parsedUrl: Record<string, string[]> = {};
  const [pathWithoutPage, p = ''] = path.split('/page');
  if (p) {
    page = Number(p.split('/')?.[1]) || page;
  }
  if (pathWithoutPage && pathWithoutPage.includes(splittedPart)) {
    [, path] = pathWithoutPage.split(splittedPart);
    if (!path) {
      return { parsedUrl, page };
    }
    const hashes = path.split('/')[0].split(';').filter(hash => !!hash);
    hashes.map(hash => {
      let val: string | string[]
      const [key] = hash.split('=');
      [, val] = hash.split('=');

      parsedUrl[key as string] = [];
      if (val && val.includes(',')) {
        val = val.split(',');
        val.forEach(v => {
          if (v.includes('/')) {
            parsedUrl[key].push(decodeURIComponent(v.split('/')[zero]));
          } else {
            parsedUrl[key].push(decodeURIComponent(v));
          }
        });
      } else if (val && val.includes('/')) {
        parsedUrl[key].push(decodeURIComponent(val.split('/')[zero]));
      } else {
        parsedUrl[key].push(decodeURIComponent(val));
      }
      return { parsedUrl, page };
    });
  }
  return { parsedUrl, page };
}

